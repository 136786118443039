/*.Header {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    background-color: #fff;*/
/*    border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
/*    padding: 24px;*/
/*}*/

.Logo {
    height: 60px;
    margin: 0 auto;
}

/*.cta-btn {*/
/*    position: fixed;*/
/*    right: 5px;*/
/*    top: 0px;*/
/*}*/

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 24px;
}

/*.Logo {*/
/*    margin-left: auto;*/
/*    margin-right: auto;*/
/*}*/

.cta-btn {
    margin-right: 10px;
}